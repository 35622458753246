/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useCallback } from 'react'
import useMedia from '../../hooks/useMedia'
import dayjs from "dayjs"
import useAppSelector from '../../hooks/useAppSelector'
import { ItemOperationProp } from '../../store/types/TypesReceivements'
import RecebimentosMobile from './Components/Mobile'
import RecebimentosDesktop from './Components/Desktop'
import { monthsString, optionType } from '../../utils/constants'
import { formatToCurrencyBRL, parseToFormattedDate } from '../../utils/functions'

export interface EventDayFormatedProps {
    title: string,
    colorTitle?: string,
    amount: number,
    colorAmount?: string,
    operations?: Array<{nsu: string, percentage: number, value: number}>
}

const Recebimentos = () => {
    const mobile = useMedia('(max-width: 40rem)')
    const option = useAppSelector(state => state.switchButtons.option)

    const {
        balanceCredmoura,
        operacoesArray,
        eventsReceivementsByDay,
    } = useAppSelector(state => state.receivements)

    //FUNÇÃO QUE FORMATA OS VALORES DE CRÉDITO OU DÉBITO POR DIA NO MÊS
    const formatedEventsMonthMoura = useCallback((events: any) => {
        let extratoResumidoPerMonth: any = [];
    
        events?.forEach((operation: any) => {
          let debito = operation.items
            .filter((item: any) => item.Type === "Saída")
            .map((item: any) => item.Valor);
          if (debito.length > 0) {
            debito = debito.reduce((total: any, item: any) => total + item);
          } else {
            debito = 0;
          }
    
          let credito = operation.items
            .filter((item: any) => item.Type === "Entrada")
            .map((item: any) => item.Valor);
          if (credito.length > 0) {
            credito = credito.reduce((total: any, item: any) => total + item);
          } else {
            credito = 0;
          }
    
          extratoResumidoPerMonth.push({
            date: operation.date,
            debito: debito,
            credito: credito,
          });
        });

        return extratoResumidoPerMonth
    }, [])

    const allEventsCalendarMoura = useMemo(() => {
        return operacoesArray?.reduce((accumlator, operation) => {
            const content = { balance: 0, ...operation };
  
            content.balance = content.items.reduce((acc, item) => {
              if (item.Type === "Entrada") {
                acc -= item.Valor;
              } else {
                acc += item.Valor * 1;
              }
              return acc;
            }, balanceCredmoura);
      
            accumlator.push(content);
      
            return accumlator;
          }, [] as {
            date: Date;
            items: ItemOperationProp[];
            balance: number;
        }[])
      },[operacoesArray, balanceCredmoura])

    const lastEventCalendarMoura: {value: number, date: string} = useMemo(() => {
        if(!allEventsCalendarMoura.length) return {value: 0, date: dayjs().locale('pt-br').format('YYYY-MM-DD')}
        const array: any = []
        formatedEventsMonthMoura(allEventsCalendarMoura)?.forEach((item: any) => {
            if(item?.credito) {
                array.push(
                    {
                        title: formatToCurrencyBRL(item?.credito),
                        date: parseToFormattedDate(item?.date),
                        backgroundColor: "#0033AB"
                    },
                )
            }
        })
        if(!array.length) return {value: 0, date: dayjs().locale('pt-br').format('YYYY-MM-DD')}
        return array.sort((a: any, b: any) => {
            const formatedDateA = new Date(`${a.date}T00:00:00`)
            const formatedDateB = new Date(`${b.date}T00:00:00`)
            return formatedDateB.getTime() - formatedDateA.getTime()
        }).map((item: any) => {
            const value = parseFloat(item.title.replace(/[^\d,-]/g, '').replace(',', '.'));
            return {
                value: value,
                date: item.date
            }
        })[0]
    }, [allEventsCalendarMoura])

    const lastEventCalendarLoja: {value: number, date: string} = useMemo(() => {
        const array = [...eventsReceivementsByDay]
        if(!array.length) return {value: 0, date: dayjs().locale('pt-br').format('YYYY-MM-DD')}

        return array?.sort((a, b) => {
            const formatedDateA = new Date(`${a.date}T00:00:00`)
            const formatedDateB = new Date(`${b.date}T00:00:00`)
            return formatedDateB.getTime() - formatedDateA.getTime()
        }).map((item) => {
            const value = parseFloat(item.title.replace(/[^\d,-]/g, '').replace(',', '.'));
            return {
                value: value,
                date: item.date
            }
        })[0]
    }, [eventsReceivementsByDay])

    const dateLastEvent = useMemo(() => {
        if(option === optionType.MOURA) {
            return `Dia ${dayjs(lastEventCalendarMoura.date).locale('pt-br').format('DD')} de ${monthsString[dayjs(lastEventCalendarMoura.date).format("MMMM")]}`
        }
        return `Dia ${dayjs(lastEventCalendarLoja.date).locale('pt-br').format('DD')} de ${monthsString[dayjs(lastEventCalendarLoja.date).format("MMMM")]}`
    },[lastEventCalendarMoura, lastEventCalendarLoja])


    return (
        <>
            {mobile === true && (
                <RecebimentosMobile
                    lastEventCalendarLoja={lastEventCalendarLoja}
                    dateLastEvent={dateLastEvent}
                />
            )}
            {mobile === false && (
                <RecebimentosDesktop
                    lastEventCalendarLoja={lastEventCalendarLoja}
                    lastEventCalendarMoura={lastEventCalendarMoura}
                    dateLastEvent={dateLastEvent}
                />
            )}
        </>
    )
}

export default Recebimentos